import { getDXLAddonProductPrice } from "@vfit/consumer/data-access";
export var getLandingPrice = function(offerProduct, product) {
    var ref, ref1, ref2, ref3, ref4;
    var price = getDXLAddonProductPrice(offerProduct === null || offerProduct === void 0 ? void 0 : (ref = offerProduct.finalPrice) === null || ref === void 0 ? void 0 : ref.value);
    if ((offerProduct === null || offerProduct === void 0 ? void 0 : (ref1 = offerProduct.finalPrice) === null || ref1 === void 0 ? void 0 : ref1.value) > 0 && (product === null || product === void 0 ? void 0 : (ref2 = product.landingPagePriceConfig) === null || ref2 === void 0 ? void 0 : ref2.isTryAndBy) === "true") {
        var ref5, ref6, ref7, ref8, ref9;
        return {
            price: "Gratis",
            recurrence: (product === null || product === void 0 ? void 0 : (ref5 = product.landingPagePriceConfig) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.tryAndBy) === null || ref6 === void 0 ? void 0 : ref6.recurrence) || "",
            label: (product === null || product === void 0 ? void 0 : (ref7 = product.landingPagePriceConfig) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.tryAndBy) === null || ref8 === void 0 ? void 0 : ref8.label) || "",
            subprice: product === null || product === void 0 ? void 0 : (ref9 = product.subprice) === null || ref9 === void 0 ? void 0 : ref9.replace("$PRICE", price || ""),
            priceVariant: "freeAmount"
        };
    }
    if ((offerProduct === null || offerProduct === void 0 ? void 0 : (ref3 = offerProduct.finalPrice) === null || ref3 === void 0 ? void 0 : ref3.value) > 0) {
        var ref10, ref11, ref12, ref13, ref14, ref15;
        return {
            price: product === null || product === void 0 ? void 0 : (ref10 = product.price) === null || ref10 === void 0 ? void 0 : ref10.replace("$PRICE", price || ""),
            recurrence: (product === null || product === void 0 ? void 0 : (ref11 = product.landingPagePriceConfig) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.fixedPrice) === null || ref12 === void 0 ? void 0 : ref12.recurrence) || "",
            label: (product === null || product === void 0 ? void 0 : (ref13 = product.landingPagePriceConfig) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.fixedPrice) === null || ref14 === void 0 ? void 0 : ref14.label) || "",
            subprice: product === null || product === void 0 ? void 0 : (ref15 = product.subprice) === null || ref15 === void 0 ? void 0 : ref15.replace("$PRICE", price || ""),
            priceVariant: ""
        };
    }
    if ((offerProduct === null || offerProduct === void 0 ? void 0 : (ref4 = offerProduct.finalPrice) === null || ref4 === void 0 ? void 0 : ref4.value) == 0) {
        var ref16, ref17, ref18, ref19, ref20;
        return {
            price: "Gratis",
            recurrence: (product === null || product === void 0 ? void 0 : (ref16 = product.landingPagePriceConfig) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.oneShot) === null || ref17 === void 0 ? void 0 : ref17.recurrence) || "",
            label: (product === null || product === void 0 ? void 0 : (ref18 = product.landingPagePriceConfig) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.oneShot) === null || ref19 === void 0 ? void 0 : ref19.label) || "",
            subprice: product === null || product === void 0 ? void 0 : (ref20 = product.subprice) === null || ref20 === void 0 ? void 0 : ref20.replace("$PRICE", price || ""),
            priceVariant: "freeAmount"
        };
    }
    return {
        price: price,
        recurrence: "",
        priceVariant: "",
        label: "",
        subprice: ""
    };
};
