import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { checkPanicMode, CMS_CONFIG, CMS_TOOL_START, getDXLProductPrice, isAppMock, ProductType, useCmsConfig, useGetActivable } from "@vfit/consumer/data-access";
import { API, AppMobile, checkWindow, getFromLocalStorageByKey, PAGES } from "@vfit/shared/data-access";
import { DEFAULT_CMS_ID, retrieveDXLBundlePo, retrieveRedirectUrl, showError } from "./retrieveProductDetails.utils";
import { getLandingPrice } from "../utils/price.utils";
import { DEFAULT_MOCK_CATALOG, DEFAULT_MOCK_CATALOG_ADDON } from "../components/editSession/editSession.utils";
export var useRetrieveProductDetails = function() {
    var ref = useState(true), isLoading = ref[0], setIsLoading = ref[1];
    var ref1 = useState(null), isActivableProduct = ref1[0], setIsActivableProduct = ref1[1];
    var ref2 = useState(""), msisdn = ref2[0], setMsisdn = ref2[1];
    var isApp = AppMobile.checkIsApp();
    var globalconfig = (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG) || {}).globalconfig;
    var panicMode = (globalconfig || {}).panicMode;
    var urlParams = new URLSearchParams(window.location.search);
    var queryClient = useQueryClient();
    var productList = queryClient.getQueryData("allProducts");
    var cmsId = (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("cmsid")) || DEFAULT_CMS_ID;
    var defaultProduct = productList === null || productList === void 0 ? void 0 : productList.find(function(p) {
        return p.cmsId === cmsId;
    });
    var isPanicMode = checkPanicMode(panicMode, defaultProduct);
    var ref3 = useState(defaultProduct || null), product = ref3[0], setProduct = ref3[1];
    var isLandingClub = (product === null || product === void 0 ? void 0 : product.isLandingClub) || false;
    var typeToRetrieve = isLandingClub ? ProductType.ADDON : ProductType.NBA;
    var redirectUrl = retrieveRedirectUrl(product);
    var ref4 = useGetActivable(msisdn, typeToRetrieve, ""), activableData = ref4.data, isErrorActivable = ref4.isError, isSuccessActivable = ref4.isSuccess, refetchGetActivable = ref4.refetch;
    var checkSession = function() {
        var appSession = isApp && checkWindow() ? getFromLocalStorageByKey("appSession") : null;
        if (appSession === null || appSession === void 0 ? void 0 : appSession.value) {
            setMsisdn(appSession.value);
        } else if (product && !(appSession === null || appSession === void 0 ? void 0 : appSession.value)) {
            var ref, ref1;
            showError(product === null || product === void 0 ? void 0 : (ref = product.pdpSecondAction) === null || ref === void 0 ? void 0 : ref.title, product === null || product === void 0 ? void 0 : (ref1 = product.pdpSecondAction) === null || ref1 === void 0 ? void 0 : ref1.url);
        }
    };
    var onSuccessActivable = function(actData) {
        if (product && isLandingClub) {
            var ref, ref1, ref2;
            var activableAddon = actData === null || actData === void 0 ? void 0 : (ref = actData.sections) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.products) === null || ref2 === void 0 ? void 0 : ref2.find(function(prod) {
                var ref, ref1;
                return (prod === null || prod === void 0 ? void 0 : (ref = prod.id) === null || ref === void 0 ? void 0 : ref.productOfferingId) == (product === null || product === void 0 ? void 0 : (ref1 = product.offerId) === null || ref1 === void 0 ? void 0 : ref1.toString());
            });
            if (activableAddon) {
                var ref3 = getLandingPrice(activableAddon, product), price = ref3.price, recurrence = ref3.recurrence, priceVariant = ref3.priceVariant, label = ref3.label, subprice = ref3.subprice;
                setProduct(_object_spread_props(_object_spread({}, product), {
                    isLoaded: true,
                    price: price,
                    recurrence: recurrence,
                    priceVariant: priceVariant,
                    label: label,
                    subprice: subprice
                }));
                setIsActivableProduct(activableAddon);
            } else {
                var ref4;
                setProduct(_object_spread_props(_object_spread({}, product), {
                    isLoaded: true,
                    price: (product === null || product === void 0 ? void 0 : (ref4 = product.price) === null || ref4 === void 0 ? void 0 : ref4.includes("$PRICE")) ? "" : (product === null || product === void 0 ? void 0 : product.price) || "",
                    action: {
                        type: 4,
                        callMeNow: product === null || product === void 0 ? void 0 : product.landingCTC
                    }
                }));
            }
        } else {
            var ref5;
            var dxlBundlePO = retrieveDXLBundlePo((actData === null || actData === void 0 ? void 0 : (ref5 = actData.sections) === null || ref5 === void 0 ? void 0 : ref5[0].products) || null);
            if (product && dxlBundlePO) {
                var ref6, ref7, ref8;
                setProduct(_object_spread_props(_object_spread({}, product), {
                    isLoaded: true,
                    price: getDXLProductPrice(dxlBundlePO) || (product === null || product === void 0 ? void 0 : product.price) || "",
                    recurrence: ((ref6 = dxlBundlePO) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.cmsCustomization) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.renewalCost) === null || ref8 === void 0 ? void 0 : ref8.frequency) || (product === null || product === void 0 ? void 0 : product.recurrence) || ""
                }));
            }
        }
        setIsLoading(false);
    };
    useEffect(function() {
        if (isErrorActivable) {
            var ref, ref1;
            showError(product === null || product === void 0 ? void 0 : (ref = product.pdpSecondAction) === null || ref === void 0 ? void 0 : ref.title, product === null || product === void 0 ? void 0 : (ref1 = product.pdpSecondAction) === null || ref1 === void 0 ? void 0 : ref1.url);
        } else if (!isAppMock() && isSuccessActivable && activableData && productList) {
            onSuccessActivable(activableData);
        }
    }, [
        isSuccessActivable,
        isErrorActivable,
        activableData,
        productList
    ]);
    useEffect(function() {
        if (msisdn && !isSuccessActivable) {
            if (!isAppMock()) {
                refetchGetActivable();
            } else {
                // simulate loader
                setTimeout(function() {
                    var mock = (product === null || product === void 0 ? void 0 : product.isLandingClub) ? DEFAULT_MOCK_CATALOG_ADDON : DEFAULT_MOCK_CATALOG;
                    var dxlCatalogResponseMock = localStorage.getItem("dxlCatalogResponse") || JSON.stringify(mock);
                    onSuccessActivable(JSON.parse(dxlCatalogResponseMock));
                }, 2000);
            }
        }
    }, [
        msisdn
    ]);
    useEffect(function() {
        checkSession();
        localStorage.setItem(CMS_TOOL_START, cmsId);
    }, []);
    return {
        product: product,
        serviceData: activableData,
        isError: isErrorActivable,
        isLoading: isLoading,
        redirectUrl: redirectUrl,
        isPanicMode: isPanicMode,
        isLandingClub: isLandingClub,
        isActivableProduct: isActivableProduct
    };
};
